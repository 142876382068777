<script>
    import GoogleLogo from '@assets/google-logo.svg';
    import Icon from '@iconify/svelte';
    import SSOButton from '@login/shared/SSOButton.svelte';
    import { translationsStore } from '@stores/translations/translations.js';
    import { enableSSO } from '@login/stores/settings.js';

    export let isSsoOnly = false;
</script>

{#if Object.values($enableSSO).some((enabled) => enabled)}
    <div class="flex items-center mb-4">
        <div class="flex-grow border-b border-gray-300"></div>
        <div class="mx-4 text-gray-600 font-normal text-xs">
            {#if isSsoOnly}
                {translationsStore.translate('platform_sso_login_with')}
            {:else}
                {translationsStore.translate('platform_sso_or_continue_with')}
            {/if}
        </div>
        <div class="flex-grow border-b border-gray-300"></div>
    </div>

    <div class="flex flex-row space-x-4">
    {#if $enableSSO.microsoft}
        <SSOButton id="sso-microsoft-login-button" provider="microsoft">
            <Icon icon="logos:microsoft" class="pl-1"/>
        </SSOButton>
    {/if}

    {#if $enableSSO.google}
        <SSOButton id="sso-google-login-button" provider="google">
            <img class="w-4" src="{GoogleLogo}" alt="Google logo">
            <span class="text-gray-500 text-xs font-semibold pl-1">{translationsStore.translate('platform_sso_sign_in')}</span>
        </SSOButton>
    {/if}
    </div>
{/if}
